<template>
  <div class="page-center">
    <div class="login-pages ">

      <b-card
        class="login-card login mb-0"
        body-class="login-card-body login-grid"
      >
        <div class="content-side">
          <div>
            <e8-logo
              style="height: 33px; "
            />
          </div>
          <h1 class="text-white font-weight-bold mt-4 font-size-32">
            Sign in<span class="text-primary">.</span>
          </h1>
          <p class="text-white">
            Help us connect talent and our capital.
          </p>
          <google-login>Login with Google</google-login>

          <b-form
            class="auth-login-form form-autofill-background mt-1 form-autofill-background-main"
            @submit.prevent="doLogin"
          >

            <b-form-group
              label-class="text-white font-weight-bold"
              label="Email address"
            >
              <b-form-input
                v-model="userEmail"
                type="email"
                class="form-control "
              />
            </b-form-group>

            <b-form-group
              label="Password"
              class="position-relative"
              label-class="text-white font-weight-bold"
            >
              <b-form-input
                v-model="password"
                :type="passwordShow?'text':'password'"
                class="form-control form-control-lg"
              />
              <feather-icon
                class="cursor-pointer position-absolute show-password"
                :icon="passwordShow? 'EyeOffIcon':'EyeIcon'"
                @click="togglePasswordVisibility"
              />
            </b-form-group>
            <p class="font-size-14 mt-n2 mb-0 text-muted">
              Use the same password as in the E8X.
            </p>
            <b-form-group
              v-if="twoFactorPending"
              label="Please insert your 2FA code"
            >
              <b-form-input
                id="code"
                v-model="twoFactor"
                v-mask="'######'"
                max-length="6"
                class="form-control form-inline form-control-lg text-center font-large-1"
                placeholder="XXX XXX"
              />
            </b-form-group>
          </b-form>
          <b-button
            class="bg-primary text-header font-size-16 mt-2 vh-center"
            type="submit"
            @click.prevent="doLogin"
          >
            <b-spinner
              v-if="loading"
              small
            />&nbsp;
            <span v-if="!loading">Sign in</span>
            <span v-if="loading">Loading...</span>
          </b-button>
        </div>
        <login-image-side class="d-none d-md-flex" />
      </b-card>
    </div>
  </div>
</template>

<script>
import {
  BButton,
  BCard,
  BForm,
  BFormGroup,
  BFormInput,
  BSpinner,
} from 'bootstrap-vue'
import Swal from 'sweetalert2'
import { mask } from 'vue-the-mask'
import E8Logo from '@/components/Logo.vue'
import LoginImageSide from '@/views/login/LoginImageSide.vue'
import GoogleLogin from '@/components/GoogleLogin.vue'

export default {
  components: {
    // BSV
    BCard,
    E8Logo,
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    BSpinner,
    LoginImageSide,
    GoogleLogin,
  },
  directives: {
    mask,
  },

  data() {
    return {
      status: '',
      password: '',
      userEmail: '',
      twoFactor: '',
      loading: false,
      twoFactorPending: false,
      passwordShow: false,
    }
  },
  methods: {
    togglePasswordVisibility() {
      this.passwordShow = !this.passwordShow
    },

    doLogin() {
      this.loading = true
      this.$store.dispatch('auth/login', {
        username: this.userEmail,
        password: this.password,
        twoFactor: this.twoFactor,
      }).catch(error => {
        this.loading = false
        if (error.status === 430) {
          this.twoFactorPending = true
          return
        }
        Swal.fire({
          title: '<span class="text-header font-size-24">Invalid login</span>',
          html: '<span class="text-main font-size-18">Please check your login credentials</span>',
          icon: 'error',
          scrollbarPadding: false,
          background: '#1E1F21',
          confirmButtonColor: '#30D5F1',
        })
      })
    },
  },

}
</script>

<style lang="scss" >
.page-center{
  min-height: calc(100vh - 64px - 24px) !important;
}
@media screen and (max-width: map-get($grid-breakpoints, 'sm')) {
.page-center{
  min-height: calc(100vh - 88px - 24px) !important;
}
}

.show-password{
  right: 10px;
  top: 50%;
  transform: translate(-50%, -50%);
}
</style>
