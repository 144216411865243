<template>
  <div class="image-side flex-column align-items-center">
    <div class="image-header d-flex justify-content-around mt-2 pt-50 px-1 px-xl-0">
      <b-link
        href="https://e8x.e8markets.com"
        class="text-white"
        target="_blank"
      >
        E8X Dashboard
      </b-link>
      <div class="separator" />
      <b-link
        href="https://e8markets.com"
        class="text-white"
        target="_blank"
      >
        E8 Markets Web
      </b-link>
    </div>
    <div
      class="my-auto font-weight-bolder text-white text-center main-text"
    >
      <span class="text-gradient">TRADE. LEARN. EARN.</span> <br>
      WITH E8 MARKETS
    </div>
    <div class="pb-50 d-flex v-center flex-column flex-lg-row">
      <div class="d-flex v-center mb-2">
        <b-link
          href="https://t.me/e8fundinggroup"
          target="_blank"
        >
          <b-img
            :src="require(`@/assets/images/icons/social/telegram.svg`)"
            alt="Telegram"
            class="mr-75 ml-2"
            style="height: 32px;"
          />
        </b-link>
        <b-link
          href="https://www.facebook.com/groups/e8markets/"
          target="_blank"
        >
          <b-img
            :src="require(`@/assets/images/icons/social/facebook.svg`)"
            alt="Facebook"
            class="mr-75"
            style="height: 32px;"
          />
        </b-link>
        <b-link
          href="https://www.instagram.com/e8funding/"
          target="_blank"
        >
          <b-img
            :src="require(`@/assets/images/icons/social/instagram.svg`)"
            alt="Instagram"
            class=""
            style="height: 32px;"
          />
        </b-link>
      </div>
      <b-link
        href="https://www.trustpilot.com/review/e8funding.com"
        target="_blank"
        class="trust-pilot d-flex v-center mb-2 mx-2 text-decoration-none"
      >
        <b-img
          :src="require(`@/assets/images/icons/trustpilot.svg`)"
          alt="Trustpilot"
          class="mr-75"
          style="height: 28px;"
        />
        <div class="font-weight-bold">
          <span class="text-white font-size-26">{{ rating }}</span><span class="trust-pilot-color">/5</span>
        </div>
      </b-link>
    </div>
  </div>
</template>

<script>
import { BImg, BLink } from 'bootstrap-vue'
import AppAPI from '@/api/app'

export default {
  components: {
    BImg,
    BLink,
  },
  data() {
    return {
      rating: 4.8,
    }
  },
  beforeMount() {
    AppAPI.getTrustpilotRating().then(data => {
      this.rating = Number(data) || 4.8
    })
  },
}
</script>

<style lang="scss" scoped>
.separator {
  background-color: white;
  width: 1px;
}

.main-text {
  font-size: 38px;
}

.trust-pilot {
  -webkit-backdrop-filter: blur(1px);
  backdrop-filter: blur(1px);
  background-color: rgba(#00B67A, 15%);
  border-radius: 7px;
  padding: 0 6px;

  .trust-pilot-color {
    color: #00B67A;
  }
}

</style>
