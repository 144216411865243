<template>
  <b-button
    variant="outline-primary"
    @click="onLogin"
  >
    <b-img
      :src="require(`@/assets/images/icons/google.svg`)"
      alt="Google"
      class="mr-75"
    />
    <span class="text-white font-weight-bold"><slot #default /></span>
  </b-button>
</template>

<script>
import { BButton, BImg } from 'bootstrap-vue'
import AuthAPI from '@/api/auth'

export default {
  components: {
    BButton,
    BImg,
  },
  methods: {
    onLogin() {
      const redirectUrl = `${window.location.origin}${this.$router.resolve({
        name: 'login-oauth2',
        params: { network: 'google' },
      }).href}`
      AuthAPI.socialAuth('google', redirectUrl).then(data => {
        window.location.href = data.loginUrl
      })
    },
  },
}
</script>

<style scoped>

</style>
